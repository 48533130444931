import React from 'react';

class About extends React.Component {
  render() {
    return (
      <section className="about-area-three ptb-120 bg-image">
        <div className="container">
          <div className="row h-100 align-items-center">
            <div className="col-lg-12">
              <div className="about-content">
                <span>Past Speakers</span>
                <h2>Past <b>Speakers</b></h2>
                <p>Since 1968, Katherine Christian Convention has featured many dynamic and challenging guest speakers from the Northern Territory and interstate.</p>

                <ul>
                  <li>
                    <i className="icofont-long-arrow-right"></i>
                    2024 - Ben van Gelderen, Revd's Craig Rogers, James Woods, Edwin Bundurr Rami, Darryn Farrell ("Jesus is the Living Water")
                  </li>
                  <li>
                    <i className="icofont-long-arrow-right"></i>
                    2023 – Bishop Greg Anderson ("Working Together for the Gospel")
                  </li>
                  <li>
                    <i className="icofont-long-arrow-right"></i>
                    2022 – No convention
                  </li>
                  <li>
                    <i className="icofont-long-arrow-right"></i>
                    2021 – Jono Wright ("Three Kings under One God")
                  </li>
                  <li>
                    <i className="icofont-long-arrow-right"></i>
                    2020 – No convention
                  </li>
                  <li>
                    <i className="icofont-long-arrow-right"></i>
                    2019 – Revd Helen Richmond ("Walking in the Light")
                  </li>
                  <li>
                    <i className="icofont-long-arrow-right"></i>
                    2018 – Revd Neville Naden ("The Faithfulness of God")
                  </li>
                  <li>
                    <i className="icofont-long-arrow-right"></i>
                    2017 – Dr Jude Long, William and Marjorie Hall, Mandy Mangurra, and Marlene Andrews ("Jubilee: Freedom and Rest in Christ")
                  </li>
                  <li>
                    <i className="icofont-long-arrow-right"></i>
                    2016 – Jono Wright ("Grace and Law")
                  </li>
                  <li>
                    <i className="icofont-long-arrow-right"></i>
                    2015 – Revd. Rob Haynes ("God's Living Water")
                  </li>
                  <li>
                    <i className="icofont-long-arrow-right"></i>
                    2014 – Pastors Jerry Jangala and Bill Morrison ("Teaching about the Holy Spirit – John Chapters 14-17")
                  </li>
                  <li>
                    <i className="icofont-long-arrow-right"></i>
                    2013 – Revd. David Woodbridge ("Life Changing Words – Stories from Jesus")
                  </li>
                  <li>
                    <i className="icofont-long-arrow-right"></i>
                    2012 – Paul & Ann Eckert ("Trusting God – Abraham and Sarah")
                  </li>
                  <li>
                    <i className="icofont-long-arrow-right"></i>
                    2011 – Revd. Tom Slockee ("Giving Ourselves to God")
                  </li>
                  <li>
                    <i className="icofont-long-arrow-right"></i>
                    2010 – Revd. Alfred Olwa ("Chosen and Called")
                  </li>
                  <li>
                    <i className="icofont-long-arrow-right"></i>
                    2009 – Revd. Canon Barry Butler ("Jesus in All the Bible")
                  </li>
                  <li>
                    <i className="icofont-long-arrow-right"></i>
                    2008 – Clayton Fergie ("Jesus the Life Changer")
                  </li>
                  <li>
                    <i className="icofont-long-arrow-right"></i>
                    2007 – Rodney Rivers, Barry Borneman ("God Speaks Through His Word")
                  </li>
                  <li>
                    <i className="icofont-long-arrow-right"></i>
                    2006 – Graham Paulson ("God's Plan for His Family")
                  </li>
                  <li>
                    <i className="icofont-long-arrow-right"></i>
                    2005 – Richard Davies ("To be a Holy Nation")
                  </li>
                  <li>
                    <i className="icofont-long-arrow-right"></i>
                    2004 – Paul & Ann Eckert ("Walking the way of Jesus")
                  </li>
                  <li>
                    <i className="icofont-long-arrow-right"></i>
                    2003 – Rev Peter Palmer ("The Gift")
                  </li>
                  <li>
                    <i className="icofont-long-arrow-right"></i>
                    2002 – Pastors Barry Downs & Jerry Jangala ("Church and Community")
                  </li>
                  <li>
                    <i className="icofont-long-arrow-right"></i>
                    2001 – Rev Greg Anderson & Rev Dhalnganda Darrawurra ("People of Hope")
                  </li>
                  <li>
                    <i className="icofont-long-arrow-right"></i>
                    2000 – Rev Dr John Harris ("Living for Jesus in Good Times and in Bad")
                  </li>
                  <li>
                    <i className="icofont-long-arrow-right"></i>
                    1999 – Rev Jovilisi Ragata ("Discipleship")
                  </li>
                  <li>
                    <i className="icofont-long-arrow-right"></i>
                    1998 – Mr Steve Etherington ("The Church")
                  </li>
                  <li>
                    <i className="icofont-long-arrow-right"></i>
                    1997 – Pastor Roy Funu ("Set My People Free")
                  </li>
                  <li>
                    <i className="icofont-long-arrow-right"></i>
                    1996 – Bishop Tony Nichols ("Relationship within God's Family")
                  </li>
                  <li>
                    <i className="icofont-long-arrow-right"></i>
                    1995 – Rev Saibo Mabo & Mr Barry Borneman ("Commitment")
                  </li>
                  <li>
                    <i className="icofont-long-arrow-right"></i>
                    1994 – Pastors Richard Davies & Richard Driver ("Abraham, Man of Faith")
                  </li>
                  <li>
                    <i className="icofont-long-arrow-right"></i>
                    1993 – Pastor Max Schenk, Canon Barry Butler, Pastor Gumbuli Wurramara & Rev Graham Paulson ("25th Anniversary – Celebrating Reconcilliation in Christ")
                  </li>
                  <li>
                    <i className="icofont-long-arrow-right"></i>
                    1992 – Mr Steve Etherington, Pastor Wali Fejo & Rev Mawundjil Garrawirrtja ("Marriage and Family – Genesis 1-3")
                  </li>
                  <li>
                    <i className="icofont-long-arrow-right"></i>
                    1991 – Rev John Bundy ("Proclaiming God's Word")
                  </li>
                  <li>
                    <i className="icofont-long-arrow-right"></i>
                    1990 – Rev John Whitbourn ("Mutual Encouragement of Believers")
                  </li>
                  <li>
                    <i className="icofont-long-arrow-right"></i>
                    1989 – Pastor Ross Bilson ("Total Commitment to Christ")
                  </li>
                  <li>
                    <i className="icofont-long-arrow-right"></i>
                    1988 – Captain Ashley Davies, Rev Jim Windslade & Pastor Rupert Nunggumajbarr ("All One in Christ")
                  </li>
                  <li>
                    <i className="icofont-long-arrow-right"></i>
                    1987 – Pastor R J McLelland ("Servants of the Lord")
                  </li>
                  <li>
                    <i className="icofont-long-arrow-right"></i>
                    1986 – Rev Jim Kime ("Christ in our Community")
                  </li>
                  <li>
                    <i className="icofont-long-arrow-right"></i>
                    1985 – Rev Arther Dean ("Feeding on the Word")
                  </li>
                  <li>
                    <i className="icofont-long-arrow-right"></i>
                    1984 – Rev Geoff Bingham ("Family of God")
                  </li>
                  <li>
                    <i className="icofont-long-arrow-right"></i>
                    1983 – Pastor Ron Williams, Rev Tony Nichols & Rev Bob George ("Jesus Christ is Lord")
                  </li>
                  <li>
                    <i className="icofont-long-arrow-right"></i>
                    1982 – Pastor Bert Clarke & Bamyii Aranjari ("The Fatherhood of God")
                  </li>
                  <li>
                    <i className="icofont-long-arrow-right"></i>
                    1981 – Pastor Wes Caddy & Pastor Ivan Jordan ("The Fruit of the Spirit is Love, Joy, Peace")
                  </li>
                  <li>
                    <i className="icofont-long-arrow-right"></i>
                    1980 – Rev T Djiniyini, Mr K Rrurranba & Rev J Goodluck ("Showing Forth Christ")
                  </li>
                  <li>
                    <i className="icofont-long-arrow-right"></i>
                    1979 – Pastor M Maeliou & Rev J Whitbourn ("Victory in the Lord Jesus Christ")
                  </li>
                  <li>
                    <i className="icofont-long-arrow-right"></i>
                    1978 – Rev Jim Taylor & Pastor Frank Johnson ("Disciples for Christ")
                  </li>
                  <li>
                    <i className="icofont-long-arrow-right"></i>
                    1977 – Mr Clifford Warne ("Growing up in Christ")
                  </li>
                  <li>
                    <i className="icofont-long-arrow-right"></i>
                    1976 – Dr Paul White ("Real Freedom")
                  </li>
                  <li>
                    <i className="icofont-long-arrow-right"></i>
                    1975 – Mr D White & Pastor R Day ("The Family")
                  </li>
                  <li>
                    <i className="icofont-long-arrow-right"></i>
                    1974 – Dr K Cole & Pastor D Humphries ("God's Power Saves Today")
                  </li>
                  <li>
                    <i className="icofont-long-arrow-right"></i>
                    1973 – Rev B Butler & Pastor Dan Pah ("Living for Jesus")
                  </li>
                  <li>
                    <i className="icofont-long-arrow-right"></i>
                    1972 – Revs E. C. Long & G. Paulson ("Maranatha – Our Lord Comes")
                  </li>
                  <li>
                    <i className="icofont-long-arrow-right"></i>
                    1971 – Pastor Fejo ("Christ – The Power to Win")
                  </li>
                  <li>
                    <i className="icofont-long-arrow-right"></i>
                    1970 – Pastor Ben Manson ("Walking Every Day with Jesus")
                  </li>
                  <li>
                    <i className="icofont-long-arrow-right"></i>
                    1969 – Rev D Mill ("Going on with Christ")
                  </li>
                  <li>
                    <i className="icofont-long-arrow-right"></i>
                    1968 – Pastor Max Schenk & Pastor Bill Parish
                  </li>
                </ul>

              </div>
            </div>
          </div>
        </div>
      </section>
    );
  }
}

export default About;